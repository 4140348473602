<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          <v-btn class="label" text color="transparent">
            <v-icon color="secondary" left size="20">$addUser</v-icon>
            <div class="font-weight-regular secondary--text text-capitalize">
              {{ $t('page_users_create_title') }}
            </div>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="auto">
          <v-btn
            small
            class="text-capitalize font-weight-regular"
            text
            @click="$router.push({ name: 'UserList' })"
          >
            <v-icon color="secondary" left>$goBack</v-icon>
            {{ $t('go_back') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit" autocomplete="off">
        <v-card-text class="custom-card-text">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="1"
                    class="text-md-right pb-0 pb-md-4 business-text"
                  >
                    {{ $t('select_business') }}
                  </v-col>

                  <v-col cols="12" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="company_id"
                      rules="required"
                    >
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        item-value="id"
                        item-text="display_name"
                        :items="businesses"
                        v-model="selectedBusiness"
                        return-object
                        :placeholder="$t('select_business')"
                        :error-messages="errors"
                      >
                        <template v-slot:selection="{ item }">
                          <span class="primary--text pr-1">{{ item.id }}</span>
                          / {{ item.display_name }}
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="5"
                offset-md="1"
                v-if="selectedBusiness !== null"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div>
                      <span class="primary--text">{{
                        this.selectedBusiness.id
                      }}</span>
                      /
                      {{ this.selectedBusiness.display_name }}
                    </div>
                    <div>
                      {{ $t('business_type') }}:
                      {{
                        this.selectedBusiness.type !== null
                          ? this.selectedBusiness.type.name
                          : ''
                      }}
                    </div>
                    <div>
                      {{ this.selectedBusiness.postcode }}
                      {{
                        this.selectedBusiness.prefecture !== null
                          ? this.selectedBusiness.prefecture.name
                          : ''
                      }}
                      {{ this.selectedBusiness.city }}
                      {{ this.selectedBusiness.address }}
                    </div>
                    <div>
                      {{ $t('tel') }}: {{ this.selectedBusiness.phone }}
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div>
                      {{ $t('number_of_employee') }}:
                      {{
                        this.selectedBusiness.head_count !== null
                          ? this.selectedBusiness.head_count.name
                          : ''
                      }}
                    </div>
                    <div>
                      {{ $t('registration_date') }}:
                      {{ this.selectedBusiness.registration_date }}
                    </div>
                    <div>
                      {{ $t('registered') }}:
                      {{ this.selectedBusiness.connected_date }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="custom-card-text">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col
                        v-for="field in fields"
                        :key="field.name"
                        cols="12"
                      >
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            {{ field.label }}
                          </v-col>

                          <template
                            v-if="
                              field.type === 'text' || field.type === 'email'
                            "
                          >
                            <v-col
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  :type="field.type"
                                  v-model="field.value"
                                  :placeholder="field.placeholder"
                                  autocomplete="off"
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col
                              v-if="field.additional_field"
                              cols="8"
                              :md="field.additional_field ? '4' : '8'"
                              offset="4"
                              offset-md="0"
                            >
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.additional_field.name"
                                :rules="field.additional_field.rules"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  flat
                                  v-model="field.additional_field.value"
                                  :placeholder="
                                    field.additional_field.placeholder
                                  "
                                  :error-messages="errors"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </template>

                          <template v-else-if="field.type === 'file'">
                            <v-col cols="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-file-input
                                  accept="image/*"
                                  placeholder="Choose File"
                                  outlined
                                  dense
                                  flat
                                  prepend-icon=""
                                  :error-messages="errors"
                                  v-model="field.value"
                                ></v-file-input>
                              </validation-provider>
                            </v-col>
                          </template>
                          <template v-else-if="field.type === 'groups'">
                            <v-col cols="8">
                              <validation-provider
                                v-slot="{ errors }"
                                :name="field.name"
                                :rules="field.rules"
                              >
                                <v-autocomplete
                                  v-model="selectedGroups"
                                  :error-messages="errors"
                                  :items="groups"
                                  item-text="name"
                                  item-value="id"
                                  :menu-props="{ maxHeight: '400' }"
                                  :placeholder="field.placeholder"
                                  multiple
                                  dense
                                  outlined
                                  chips
                                  deletable-chips
                                  small-chips
                                  type="text"
                                ></v-autocomplete>
                              </validation-provider>
                            </v-col>
                          </template>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-row align="center">
                      <v-col cols="12">
                        <v-row align="center">
                          <v-col cols="4" md="2" class="text-right label-text">
                            {{ $t('password') }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              v-slot="{ errors }"
                              name="password"
                              :rules="passwordRules"
                            >
                              <v-text-field
                                outlined
                                dense
                                flat
                                :type="inputPassword ? 'password' : 'text'"
                                v-model="password"
                                :placeholder="$t('enter_password')"
                                append-icon="mdi-eye"
                                @click:append="toggleInputPassword"
                                :error-messages="errors"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" md="8" offset-md="2">
                        <v-row>
                          <v-col cols="12">
                            <v-card class="info-card" flat>
                              <v-card-text>
                                <div class="mb-2">
                                  {{
                                    $t('page_users_create_password_hint_text')
                                  }}
                                </div>
                                <ul>
                                  <li>
                                    {{
                                      $t(
                                        'page_users_create_password_hint_first_rule_text'
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsCorrectLength
                                          ? 'success'
                                          : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsCorrectLength
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        'page_users_create_password_hint_second_rule_text'
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsUppercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsUppercase
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        'page_users_create_password_hint_third_rule_text'
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsLowercase ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsLowercase
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        'page_users_create_password_hint_fourth_rule_text'
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsNumber ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsNumber
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                  <li>
                                    {{
                                      $t(
                                        'page_users_create_password_hint_fifth_rule_text'
                                      )
                                    }}
                                    <v-icon
                                      :color="
                                        containsSymbol ? 'success' : 'error'
                                      "
                                      size="16"
                                    >
                                      {{
                                        containsSymbol
                                          ? 'mdi-check'
                                          : 'mdi-close'
                                      }}
                                    </v-icon>
                                  </li>
                                </ul>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <v-row>
                              <v-col v-if="passwordStrength >= 1" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-weak"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 2" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-medium"
                                ></v-btn>
                              </v-col>
                              <v-col v-if="passwordStrength >= 3" cols="4">
                                <v-btn
                                  x-small
                                  rounded
                                  block
                                  class="label elevation-0 password password-strong"
                                ></v-btn>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col
                            v-if="passwordStrength >= 1"
                            cols="12"
                            class="text-center"
                          >
                            <span
                              :class="`password-${passwordStrengthText}-text`"
                            >
                              {{
                                $t('your_password_is', {
                                  strength: passwordStrengthText
                                })
                              }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row align="center" justify="center">
                      <v-col cols="auto">
                        <v-checkbox
                          :ripple="false"
                          hide-details
                          on-icon="$customCheckboxOn"
                          off-icon="$customCheckboxOff"
                          v-model="notificationEmail"
                        >
                          <template v-slot:label>
                            <div class="checkbox-label">
                              {{
                                $t('page_users_create_notification_email_text')
                              }}
                            </div>
                          </template>
                        </v-checkbox>
                        <v-checkbox
                          :ripple="false"
                          hide-details
                          on-icon="$customCheckboxOn"
                          off-icon="$customCheckboxOff"
                          v-model="passwordEmail"
                        >
                          <template v-slot:label>
                            <div class="checkbox-label">
                              {{ $t('page_users_create_password_email_text') }}
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="text-center">
                    <v-btn
                      type="submit"
                      :disabled="formStatus"
                      :loading="formStatus"
                      color="primary"
                      class="text-capitalize font-weight-regular"
                      depressed
                      small
                    >
                      {{ $t('page_users_create_submit_button') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
export default {
  name: 'index',
  computed: {
    groups() {
      return this.$store.getters.groups
    },
    businesses() {
      return this.$store.getters.userGetCompanyList
    },
    passwordStrength() {
      if (!this.password) return 0
      let strong = RegExp(
        '^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$',
        'g'
      )
      let medium = new RegExp(
        '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
      )
      if (strong.test(this.password)) {
        return 3
      } else if (medium.test(this.password)) {
        return 2
      } else {
        return 1
      }
    },
    passwordStrengthText() {
      return this.passwordStrength === 3
        ? this.$t('strong')
        : this.passwordStrength === 2
        ? this.$t('medium')
        : this.$t('weak')
    },
    containsCorrectLength() {
      return this.password.match(/(^.{8,20}$)/)
    },
    containsUppercase() {
      return this.password.match(/([A-Z])/)
    },
    containsLowercase() {
      return this.password.match(/([a-z])/)
    },
    containsNumber() {
      return this.password.match(/([0-9])/)
    },
    containsSymbol() {
      return this.password.match(/[^ A-Za-z0-9]/)
    }
  },
  created() {
    this.$store.dispatch('USER_GET_COMPANY_LIST')
    this.$store.dispatch('GROUP_GET_ALL')
  },
  data() {
    return {
      valid: false,
      inputPassword: true,
      password: '',
      passwordScore: 0,
      passwordRules: 'required|password',
      fields: [
        {
          label: this.$t('uc_name'),
          name: 'last_name',
          type: 'text',
          placeholder: this.$t('uc_name_last_placeholder'),
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name',
            placeholder: this.$t('uc_name_first_placeholder'),
            value: '',
            rules: 'required'
          }
        },
        {
          label: this.$t('uc_furigana'),
          name: 'last_name_kana',
          type: 'text',
          placeholder: this.$t('uc_furigana_last_placeholder'),
          value: '',
          rules: 'required',
          additional_field: {
            type: 'text',
            name: 'first_name_kana',
            placeholder: this.$t('uc_furigana_first_placeholder'),
            value: '',
            rules: 'required'
          }
        },
        {
          label: this.$t('uc_position'),
          name: 'position',
          type: 'text',
          placeholder: this.$t('uc_position_placeholder'),
          value: '',
          rules: ''
        },
        {
          label: this.$t('uc_email'),
          name: 'email',
          type: 'text',
          placeholder: this.$t('uc_email_placeholder'),
          value: '',
          rules: 'required'
        },
        {
          label: this.$t('uc_groups'),
          name: 'groups',
          type: 'groups',
          placeholder: this.$t('uc_groups_placeholder'),
          value: '',
          rules: ''
        },
        {
          label: this.$t('uc_photo'),
          name: 'photo',
          type: 'file',
          placeholder: this.$t('uc_photo_placeholder'),
          value: null,
          rules: 'size:5000'
        }
      ],
      selectedGroups: null,
      selectedBusiness: null,
      notificationEmail: false,
      passwordEmail: false,
      formStatus: false
    }
  },
  methods: {
    toggleInputPassword() {
      this.inputPassword = !this.inputPassword
    },
    async submit() {
      this.formStatus = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false
          return
        }

        const data = new FormData()

        this.fields.forEach(field => {
          if ({}.hasOwnProperty.call(field, 'additional_field')) {
            data.append(
              field.additional_field.name,
              field.additional_field.value
            )
          }
          if (field.name == 'photo' && (!field.value || field.value == null)) {
            //
          } else {
            data.append(field.name, field.value)
          }
        })

        if (this.selectedGroups && this.selectedGroups.length > 0) {
          for (const group of this.selectedGroups) {
            data.append('groups[]', group)
          }
        }

        data.append('company_id', this.selectedBusiness.id)
        data.append('password', this.password)
        data.append('notification_email', this.notificationEmail ? '1' : '0')
        data.append('password_email', this.passwordEmail ? '1' : '0')

        this.$store
          .dispatch('CREATE_USER', data)
          .then(result => {
            if (result.status === 200) this.$refs.observer.reset()

            this.$router.push({
              name: 'UserList'
            })
          })
          .finally(() => (this.formStatus = false))
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
